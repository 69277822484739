<template>
  <div class="add-user-page">
    <div class="page-title">Add User</div>
    <UserBaseForm
      :mode="'add'"
      @apply="onFormApply"
      @cancel="onFormCancel"
    />
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { notification } from 'ant-design-vue';
import UserBaseForm from './UserBaseForm.vue';

import api from '@/services/api';

export default {
  components: {
    UserBaseForm,
  },
  setup() {
    const router = useRouter();

    const onFormApply = async (obj) => {
      // console.log('FORM Apply', obj);
      const result = await api
        .addUser({
          ...obj,
        })
        .catch((e) => {
          if (e.response && e.response.data) {
            notification.error({
              message: 'Error',
              description: 'New user add failed, ' + e.response.data.message,
            });
          }
          return false;
        });
      if (result && result.message) {
        notification.success({
          message: 'Success',
          description: 'New user added',
        });
        setTimeout(() => {
          router.replace({ name: 'user-manager' });
        }, 500);
      }
      // else {
      //   notification.error({
      //     message: 'Error',
      //     description: 'New user add failed',
      //   });
      // }
    };
    const onFormCancel = () => {
      console.log('FORM Cancel');
      router.replace({ name: 'user-manager' });
    };
    return {
      onFormApply,
      onFormCancel,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-user-page {
  padding: 36px 30px 18px 30px;
  height: 100%;
  overflow: auto;
  .page-title {
    font-size: 20px;
    font-weight: 500;
    color: #272b41;
    margin-bottom: 36px;
  }
}
</style>
