<template>
  <a-card class="user-form">
    <template #title>
      <div
        v-if="mode === 'add'"
        class="add-title"
      >
        Add New User
      </div>
      <div
        v-if="mode === 'edit'"
        class="edit-title"
      >
        Edit User :
        <span class="username-input">{{ inputData.username }}</span>
      </div>
    </template>
    <a-row
      class="form-wrapper"
      :wrap="true"
      :gutter="[16, 16]"
    >
      <a-col
        class="left-form"
        :xs="24"
        :sm="12"
      >
        <div class="inner-wrapper">
          <div class="col-title">
            <FeatherIcon type="user" />
            <span class="col-title-text">Personal Information</span>
          </div>
          <div class="form-row-title">Username</div>
          <div class="form-row-input">
            <a-input
              v-model:value="inputData.username"
              class="input-border"
              placeholder=""
              :disabled="mode === 'edit'"
            />
          </div>
          <div class="form-row-title">Email</div>
          <div class="form-row-input">
            <a-input
              v-model:value="inputData.email"
              class="input-border"
              placeholder=""
            />
          </div>
          <div class="form-row-title">Role</div>
          <div
            id="role-input"
            class="form-row-input"
          >
            <a-select
              v-model:value="inputData.role"
              :getPopupContainer="(trigger) => trigger.parentNode"
              style="width: 100%"
            >
              <a-select-option value="user">user</a-select-option>
              <a-select-option value="admin">admin</a-select-option>
              <a-select-option value="accountmanager"
                >account manager</a-select-option
              >
              <a-select-option value="viewer">viewer</a-select-option>
            </a-select>
          </div>
          <div class="form-row-title">Status</div>
          <div class="form-row-input">
            <div class="d-flex align-items-center">
              <a-switch v-model:checked="inputData.status" />
              <span class="ml-1"> Active</span>
            </div>
          </div>
          <div
            class="form-row-reset"
            :class="{ isDisabled: noEmailInput }"
          >
            <a
              v-if="mode === 'edit'"
              class="text-reset"
              @click="toggleResetPassword"
            >
              {{ isLoading ? 'Loading...' : 'Reset Password' }}
            </a>
          </div>

          <a-form-item v-if="showNoEmailInputAlert">
            <a-alert type="error">
              <template #description>Please enter your email.</template>
            </a-alert>
          </a-form-item>

          <a-form-item v-if="result === 'success'">
            <a-alert
              type="success"
              show-icon
              closable
              @close="result = null"
            >
              <template #description
                >Password reset successful <br />Please check your
                email</template
              >
            </a-alert>
          </a-form-item>
          <a-form-item v-if="result === 'error'">
            <a-alert
              type="error"
              show-icon
              closable
              @close="result = null"
            >
              <template #description
                >The account you entered does not exist.<br />
                Please double-check and try again or <br />
                <a href="#">contact support </a></template
              >
            </a-alert>
          </a-form-item>
        </div>
      </a-col>
      <a-col
        class="right-form"
        :xs="24"
        :sm="12"
      >
        <div class="col-title">
          <FeatherIcon type="bell" />
          <span class="col-title-text">Notification</span>
        </div>
        <div class="d-flex justify-content-between">
          <div class="form-row-title">Notification Type</div>
          <a @click="toggleAllNotification"> Select all </a>
        </div>
        <div class="noti-table">
          <div class="noti-row">
            <span class="noti-type">
              Receive weekly and monthly
              <span class="bold">summary report</span>
            </span>
            <a-switch v-model:checked="inputData.isAcceptReport" />
          </div>
          <div class="noti-row">
            <span class="noti-type">
              Notify me when <span class="bold">alert keyword</span> is detected
            </span>
            <a-switch v-model:checked="inputData.isAcceptSpecialKeyword" />
          </div>
          <div class="noti-row">
            <span class="noti-type">
              Notify me when <span class="bold">DOM setting is updated</span>
            </span>
            <a-switch v-model:checked="inputData.isAcceptSettingChange" />
          </div>
          <!-- <div class="noti-row">
            <span class="noti-type"> Notify me when system detects <span class="bold"> abnormal activity </span> </span>
            <a-switch v-model:checked="inputData.isAcceptAnomaly" />
          </div> -->
          <!--          <div class="noti-row">-->
          <!--            <span class="noti-type"> Notify me when <span class="bold">data usage is nearing the limit</span> </span>-->
          <!--            <a-switch v-model:checked="inputData.isNearLimit" />-->
          <!--          </div>-->
        </div>
      </a-col>
    </a-row>
    <div class="footer-row">
      <div class="delete-user-wrapper">
        <span
          v-if="mode === 'edit'"
          class="delete-user"
        >
          <img :src="require('@/assets/images/icon/delete.svg')" />
          <a-button
            class="delete-button"
            @click="onDeleteUser"
            >Delete User</a-button
          >
        </span>
      </div>
      <div>
        <a-button
          class="cancel-button"
          type="default"
          @click="onCancel"
          >Cancel</a-button
        >
        <a-button
          type="primary"
          @click="onSave"
        >
          {{ mode === 'add' ? 'Add User' : 'Save Change' }}
        </a-button>
      </div>
    </div>
  </a-card>
</template>

<script>
import { reactive, computed, ref } from 'vue';
import api from '@/services/api';

export default {
  props: {
    mode: {
      type: String,
      default: 'add',
    },
    userData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['apply', 'cancel', 'deleteUser'],
  setup(props, { emit }) {
    const isLoading = ref(false);
    const result = ref(null);

    const inputData = reactive({
      username: '',
      email: '',
      role: 'user',
      status: true, // 'active':'inactive'
      isAcceptAnomaly: true,
      isAcceptSettingChange: true,
      isAcceptSpecialKeyword: true,
      isAcceptReport: true,
      isNearLimit: true,
      loginType: 'dom',
    });

    if (props.mode === 'edit') {
      Object.assign(inputData, props.userData);

      // convert value
      if (props.userData.status === 'active') {
        inputData.status = true;
      } else {
        inputData.status = false;
      }
    }

    const isAcceptAllNoti = computed(() => {
      const {
        isAcceptAnomaly,
        isAcceptSettingChange,
        isAcceptSpecialKeyword,
        isAcceptReport,
      } = inputData;
      return (
        isAcceptAnomaly &&
        isAcceptSettingChange &&
        isAcceptSpecialKeyword &&
        isAcceptReport
      );
    });

    const toggleAllNotification = () => {
      if (isAcceptAllNoti.value) {
        inputData.isAcceptAnomaly = false;
        inputData.isAcceptSettingChange = false;
        inputData.isAcceptSpecialKeyword = false;
        inputData.isAcceptReport = false;
      } else {
        inputData.isAcceptAnomaly = true;
        inputData.isAcceptSettingChange = true;
        inputData.isAcceptSpecialKeyword = true;
        inputData.isAcceptReport = true;
      }
    };

    const toggleResetPassword = async () => {
      // TODO add logic
      const { brand, email } = inputData;
      isLoading.value = true;
      if (brand && email) {
        let response = await api.forgotPassword(brand, email).catch(() => {});
        if (response && response.code === 200) {
          result.value = 'success';
        } else {
          result.value = 'error';
        }
      } else {
        result.value = null;
      }
      isLoading.value = false;
    };

    const noEmailInput = computed(() => {
      return (
        (inputData && inputData.email === '') ||
        (inputData && inputData.email === null)
      );
    });

    const showNoEmailInputAlert = computed(() => {
      if (noEmailInput.value) {
        return true;
      }
      return false;
    });

    const onSave = () => {
      const converted = {
        ...inputData,
        status: inputData.status ? 'active' : 'inactive',
      };
      emit('apply', converted);
    };

    const onCancel = () => {
      emit('cancel');
    };

    const onDeleteUser = () => {
      emit('deleteUser');
    };

    return {
      inputData,
      toggleAllNotification,
      toggleResetPassword,
      onSave,
      onCancel,
      onDeleteUser,
      result,
      isLoading,
      noEmailInput,
      showNoEmailInputAlert,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-form {
  .edit-title {
    .username-input {
      margin-left: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #097cff;
    }
  }
  .form-wrapper {
    .col-title {
      display: flex;
      color: #097cff;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 24px;
      align-items: center;
      .col-title-text {
        margin-left: 8px;
      }
    }
    .left-form .inner-wrapper {
      max-width: 400px;
      overflow: hidden;
    }
    .form-row-title {
      font-size: 14px;
      font-weight: 500;
      color: #272b41;
      margin-bottom: 10px;
    }
    .form-row-input {
      margin-bottom: 24px;

      .input-border {
        border-radius: 4px;
        height: 42px;
      }
    }

    .form-row-reset {
      margin-bottom: 30px;
      .text-reset {
        font-size: 13px;
        letter-spacing: 0.05em;
        color: #097cff;
      }
    }

    .noti-table {
      .noti-row {
        padding: 16px;
        border: 1px solid #f1f2f6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .noti-type {
          font-size: 14px;
          color: #5a5f7d;
          .bold {
            font-weight: 500;
          }
        }
      }
    }

    .isDisabled {
      color: currentColor;
      cursor: not-allowed;
      opacity: 0.5;
    }
    .isDisabled > a {
      color: currentColor;
      pointer-events: none;
      text-decoration: none;
    }
  }
  .footer-row {
    border-top: 1px solid #f1f2f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;

    .delete-user-wrapper {
      .delete-user {
        display: flex;
        align-items: center;
        .delete-button {
          margin-left: 9px;
          padding: 0px;
          letter-spacing: 0.05em;
          color: #ff4d4f;
        }
      }
    }

    .cancel-button {
      margin-right: 8px;
      background: #f8f9fb;
      border: 1px solid #f1f2f6;
      box-sizing: border-box;
      border-radius: 6px;
    }
  }
}
</style>
